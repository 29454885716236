import {MdModeEdit} from "@react-icons/all-files/md/MdModeEdit";
import EntityListSection from "./EntityListSection";
import {createStore, editStoreById, getAllPaginatedStores} from "../../APIs/store";

const SellersSection = () => {
    const generateTableConfig = (handleEditClick) => ({
        headers: [
            {value: "نام فروشنده", sort: true},
            {value: "شماره تلفن"},
            {value: "تعداد درخواست‌ها", sort: true},
            {value: "مجموع خریدها", sort: true},
            {value: "ویرایش", width: "50px"}
        ],
        transformRow: (store) => [
            {value: store.name},
            {value: store.phone_number ? store.phone_number : '_'},
            {value: store.inquiry_count},
            {value: `${store.total_spending.toLocaleString()} ریال`},
            {
                value: (
                    <MdModeEdit
                        style={{cursor: "pointer"}}
                        onClick={() => handleEditClick(store)}
                    />
                ),
                width: "50px"
            }
        ]
    });

    return (
        <EntityListSection
            entityType="stores"
            sortDict={{0: "name", 2: "inquiry_count", 3: "total_spending"}}
            fetchEntities={getAllPaginatedStores}
            editEntity={editStoreById}
            headerText="فهرست فروشندگان ثبت شده"
            newButtonText="فروشنده جدید"
            generateTableConfig={generateTableConfig}
            editModalTitle={"ویرایش مشخصات فروشنده"}
            editModalFields={[
                {name: 'name', label: 'نام', type: 'text'},
                {name: 'phone_number', label: 'شماره تلفن', type: 'tel'},
            ]}
            newModalTitle={"اضافه کردن فروشنده جدید"}
            newModalFields={[
                {name: 'name', label: 'نام فروشنده', type: 'text'},
                {name: 'phone_number', label: 'شماره تلفن', type: 'tel'},
            ]}
            newEntity={createStore}
            handleDisable={null}
        />
    );
};

export default SellersSection;