import React from "react";
import {Modal, Box, Typography, List, ListItem} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {CacheProvider} from "@emotion/react";
import createCache from "@emotion/cache";

const cacheRtl = createCache({
    key: "muirtl",
});

const theme = createTheme({
    direction: "rtl",
    typography: {
        fontFamily: "IRANSans, Arial, sans-serif",
    },
});

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    direction: "rtl",
    textAlign: "right"
};

const ProductDetailsModal = ({product, onClose, open}) => {
    const hasAttributes = product.attributes && Object.keys(product.attributes).length > 0;

    return (
        <CacheProvider value={cacheRtl}>
            <ThemeProvider theme={theme}>
                <Modal
                    open={open}
                    onClose={onClose}
                    aria-labelledby="product-details-modal"
                    aria-describedby="product-details-description"
                >
                    <Box sx={modalStyle}>
                        <Typography
                            variant="h5"
                            component="h2"
                            gutterBottom
                            sx={{textAlign: 'center'}}
                        >
                            جزئیات کالا
                        </Typography>

                        <List sx={{mt: 2}}>
                            <ListItem sx={{display: 'flex', px: 0}}>
                                <Typography>
                                    <strong>• گروه کالا:</strong> {product.main_category}
                                </Typography>
                            </ListItem>
                            <ListItem sx={{display: 'flex', px: 0}}>
                                <Typography>
                                    <strong>• زیرگروه کالا:</strong> {product.sub_category}
                                </Typography>
                            </ListItem>
                            <ListItem sx={{display: 'flex', px: 0}}>
                                <Typography>
                                    <strong>• برند کالا:</strong> {product.brand}
                                </Typography>
                            </ListItem>
                            <ListItem sx={{display: 'flex', px: 0}}>
                                <Typography>
                                    <strong>• نام کالا:</strong> {product.name}
                                </Typography>
                            </ListItem>
                        </List>

                        {hasAttributes && (
                            <Box sx={{mt: 3}}>
                                <Typography sx={{mb: 1}}>
                                    <strong>ویژگی‌ها:</strong>
                                </Typography>
                                <List sx={{pl: 0}}>
                                    {Object.entries(product.attributes).map(([key, value]) => (
                                        <ListItem key={key} sx={{px: 0}}>
                                            <Typography>
                                                <strong>• {key}:</strong> {value}
                                            </Typography>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        )}
                    </Box>
                </Modal>
            </ThemeProvider>
        </CacheProvider>
    );
};

export default ProductDetailsModal;