import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import stylesMobile from "../../styles/user/mobile/NewInquiry.module.css";
import stylesDesktop from "../../styles/user/desktop/NewInquiry.module.css";
import {FaChevronRight} from "@react-icons/all-files/fa/FaChevronRight";
import {FaInfoCircle} from "@react-icons/all-files/fa/FaInfoCircle";
import {FaRegTrashAlt} from "@react-icons/all-files/fa/FaRegTrashAlt";
import {FaCloudUploadAlt} from "@react-icons/all-files/fa/FaCloudUploadAlt";
import {IoMdDownload} from "@react-icons/all-files/io/IoMdDownload";
import AutoGrowInput from '../../components/AutoGrowInput';
import {toast, ToastContainer} from "react-toastify";
import toast_style from '../../styles/Toastify.css';
import InlineError from "../../components/InlineError/InlineError";
import PropTypes from "prop-types";
import LoadingComponent from "../../components/Loading/Loading";
import {compressImage} from "../../components/CompressImage";
import PDFPreview from "../../components/PDFPreview/PDFPreview";
import {isReceiptProcessedForPhoto} from "../../APIs/receipt";
import TimeLine from "../../components/TimeLine/TimeLine";
import {cancelInquiry, createInquiry, editInquiry, getInquiryWithPhotos} from "../../APIs/inquiry";
import CustomTable from "../../components/CustomTable/CustomTable";
import ConfirmationDialog from "../../components/Confirmation/ConfirmationDialog";
import SuccessDialog from "../../components/Success/SuccessDialog";
import {getFirstCommentAfterReturned} from "../../APIs/inquiry";
import InquiryStatusIcon from "../../components/InquiryStatusIcon";
import {FaRegComment} from "@react-icons/all-files/fa/FaRegComment";
import InquiryHistory from "../common/InquiryHistory";
import convertDateToShamsi from "../../utils/functions"
import useComments from "../../hooks/useComments";
import ReactLoading from "react-loading";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";


// FIXME(High): when isEdit is true but no changes have been made, the edit button should be disabled.
const NewInquiryModule = ({isEdit, isMobile}) => {
    const styles = isMobile ? stylesMobile : stylesDesktop;
    const navigate = useNavigate();
    const {id} = useParams();
    const [loading, setLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);

    // State variables fetched in useEffect
    const [inquiryDescription, setInquiryDescription] = useState('');
    const [inquirySubject, setInquirySubject] = useState('');
    const [images, setImages] = useState([]);
    const [inquiryStatus, setInquiryStatus] = useState(0);
    const [inquiryName, setInquiryName] = useState('');
    const [isInquiryReturned, setIsInquiryReturned] = useState(false);
    const [inquiryReturnedComment, setInquiryReturnedComment] = useState(false);
    const [inquirySubmittedDate, setInquirySubmittedDate] = useState('');

    const [selectedImage, setSelectedImage] = useState('');
    const [starredImageIndex, setStarredImageIndex] = useState(-1);
    const [canCancel, setCanCancel] = useState(true);
    const [uploadProgress, setUploadProgress] = useState(0);

    // Errors
    const [showCommentError, setShowCommentError] = useState(false);
    const [showSubjectError, setShowSubjectError] = useState(false);
    const [showImagesError, setShowImagesError] = useState(false);
    const [showStarredImageError, setShowStarredImageError] = useState(false);
    const [showFileErrorMessage, setShowFileErrorMessage] = useState(false);
    const [errorState, setErrorState] = useState(false);

    // Preventing multiple inquiry creations
    const [isSubmitting, setIsSubmitting] = useState(false);

    // New state variables for confirmation dialogs
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showSendConfirmation, setShowSendConfirmation] = useState(false);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [showInquiryEditionSuccess, setShowInquiryEditionSuccess] = useState(false);
    const [showInquiryCreationSuccess, setShowInquiryCreationSuccess] = useState(false);
    const [showInquiryCancellationSuccess, setShowInquiryCancellationSuccess] = useState(false);
    const [indexToDelete, setIndexToDelete] = useState(null);

    const [showInquiryHistoryModal, setShowInquiryHistoryModal] = useState(false);

    // State for showing explanation modals
    const [openSubjectExplanation, setOpenSubjectExplanation] = useState(false);
    const [openUploadingExplanation, setOpenUploadingExplanation] = useState(false);

    // Handlers for modals
    const handleOpenSubjectExplanation = () => {
        setOpenSubjectExplanation(true);
    };

    const handleCloseSubjectExplanation = () => {
        setOpenSubjectExplanation(false);
    };

    const handleOpenUploadingExplanation = () => {
        setOpenUploadingExplanation(true);
    };

    const handleCloseUploadingExplanation = () => {
        setOpenUploadingExplanation(false);
    };

    // Ref to store the first error element
    const firstErrorRef = useRef(null);

    useEffect(() => {
        // Scroll to the first error when component updates
        if (firstErrorRef.current) {
            firstErrorRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [errorState]);

    // Comment
    const {
        newComment,
        timelineItems,
        handleCommentChange,
        handleAddNewComment,
        isFetchingComments,
        isSendingComment
    } = useComments(id);
    //

    // Download PDF
    const [selectedPdf, setSelectedPdf] = useState(null);

    const initialState = useRef({
        // description: '',
        images: [],
        starredIndex: -1
    });

    useEffect(() => {
        // FIXME(Medium): edit not existing inquiries should not be processed
        if (isEdit === true) {
            const fetchInquiry = async () => {
                setLoading(true);
                try {
                    const inquiryData = await getInquiryWithPhotos(id);
                    const fetchedImages = inquiryData.data.inquiry.photos.map(photo => ({
                        id: photo.id,
                        data_uri: photo.data_uri,
                        is_selected_by_auditor: photo.is_selected_by_auditor,
                        file_type: photo.file_type
                    }));
                    setImages(fetchedImages);
                    for (let i = 0; i < inquiryData.data.inquiry.photos.length; i++) {
                        if (inquiryData.data.inquiry.photos[i].is_selected_by_user) {
                            setStarredImageIndex(i);
                        }
                        const isReceiptProcessed = await
                            isReceiptProcessedForPhoto(inquiryData.data.inquiry.photos[i].id);
                        if (isReceiptProcessed.data.exist) {
                            setCanCancel(false);
                        }
                    }
                    setInquiryStatus(inquiryData.data.inquiry.status);
                    setInquiryName(inquiryData.data.inquiry.name);
                    setInquirySubject(inquiryData.data.inquiry.subject);
                    setInquirySubmittedDate(inquiryData.data.inquiry.submitted_date);
                    setIsInquiryReturned(inquiryData.data.inquiry.is_returned);

                    // Inquiry Returned comment
                    const returnedComment = await getFirstCommentAfterReturned(id);
                    setInquiryReturnedComment(returnedComment.data.comment);

                    // Store initial state
                    initialState.current = {
                        // description: inquiryData.data.inquiry.description,
                        images: inquiryData.data.inquiry.photos,
                        starredIndex: inquiryData.data.inquiry.photos.findIndex(photo => photo.is_selected_by_user)
                    };
                } catch (error) {
                    console.error('Error fetching user inquiries:', error);
                } finally {
                    setLoading(false);
                }
            };
            fetchInquiry();
        }
    }, [id, isEdit]);

    const handleInquiryDescriptionChange = (value) => {
        setInquiryDescription(value);
        setShowCommentError(value.trim() === '');
    };

    const handleInquirySubjectChange = (event) => {
        setInquirySubject(event.target.value);
        setShowSubjectError(event.target.value.trim() === '');
    };

    const handleSelectedImage = (dataUri, file_type) => {
        const newImage = {
            id: -1,
            data_uri: dataUri,
            is_selected_by_user: false,
            is_selected_by_auditor: false,
            file_type: file_type
        };
        setImages([...images, newImage]);
        setShowImagesError(false);

    };

    const handleStarImage = (index) => {
        if (starredImageIndex === index) {
            setStarredImageIndex(-1); // Un-star the image if it's already starred
        } else {
            setStarredImageIndex(index);
            setShowStarredImageError(false);
        }
    };

    const handleDeleteImage = async (index) => {
        const isReceiptProcessed = await isReceiptProcessedForPhoto(images[index].id);
        if (isReceiptProcessed.data.exist) {
            toast.warn("پیش‌فاکتور مورد نظر پردازش شده است، بنابراین نمی‌توانید آن را حذف کنید.", {autoClose: 3000});
        } else {
            setIndexToDelete(index);
            setShowDeleteConfirmation(true);
        }
    };

    const confirmDeleteImage = () => {
        setShowDeleteConfirmation(false);
        const updatedImages = [...images];
        updatedImages.splice(indexToDelete, 1);
        if (starredImageIndex === indexToDelete) {
            setStarredImageIndex(-1);
        } else if (starredImageIndex > indexToDelete && starredImageIndex !== -1) {
            setStarredImageIndex(starredImageIndex - 1);
        }
        setImages(updatedImages);
        if (updatedImages.length === 0) {
            setShowImagesError(true);
        }
    };

    const handleSendRequest = () => {
        let hasError = false;

        if (!isEdit) {
            if (inquiryDescription.trim() === '') {
                setShowCommentError(true);
                hasError = true;
            } else {
                setShowCommentError(false);
            }

            if (inquirySubject.trim() === '') {
                setShowSubjectError(true);
                hasError = true;
            } else {
                setShowSubjectError(false);
            }
        }

        if (images.length === 0) {
            setShowImagesError(true);
            hasError = true;
        } else {
            setShowImagesError(false);
        }

        if (starredImageIndex === -1) {
            setShowStarredImageError(true);
            hasError = true;
        } else {
            setShowStarredImageError(false);
        }

        if (hasError) {
            setErrorState(prev => !prev);
        }

        if (!hasError) {
            setShowSendConfirmation(true);
        }
    };

    const confirmSendRequest = async () => {
        setIsSubmitting(true);
        setShowSendConfirmation(false);
        setUploadLoading(true);
        setUploadProgress(0);

        try {
            if (isEdit) {
                if (isFormModified()) {
                    await editInquiry(id, images, starredImageIndex);
                    setShowInquiryEditionSuccess(true);
                }
            } else {
                // const response = await createInquiry(inquiryDescription, inquirySubject);
                const response = await createInquiry(images, inquiryDescription, inquirySubject, starredImageIndex, (progress) => {
                    setUploadProgress(progress); // Update the progress bar
                });

                // for (let i = 0; i < images.length; i++) {
                //     setUploadingFileNumber(i + 1);
                //     await createFile(response.data.inquiry_id, images[i].data_uri, i === starredImageIndex, (progress) => {
                //         setUploadProgress(progress); // Update the progress bar
                //     });
                // }
                setInquiryName(response.data.inquiry_name);
                setShowInquiryCreationSuccess(true);
            }
        } catch (error) {
            console.error('Upload failed:', error);
            toast.error('هنگام بارگذاری خطایی رخ داد. لطفاً دوباره تلاش کنید.');
            setIsSubmitting(false);
        } finally {
            setUploadLoading(false);
        }
    };

    const handleDiscardButton = () => {
        if (inquiryStatus === 1 && canCancel) {
            setShowCancelConfirmation(true);
        } else {
            navigate("/user");
        }
    };

    const confirmCancelInquiry = async () => {
        setShowCancelConfirmation(false);
        try {
            await cancelInquiry(id);
            setShowInquiryCancellationSuccess(true);
        } catch (error) {
            console.error('Error cancelling inquiry:', error);
        }
    };

    const arraysEqual = (a, b) => {
        if (a.length !== b.length) return false;
        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    };

    // Check if any changes have been made
    const isFormModified = () => {
        const initialImagesDataUris = initialState.current.images.map(image => image.id);
        const currentImagesDataUris = images.map(image => image.id);
        return (
            // inquiryDescription !== initialState.current.description ||
            !arraysEqual(currentImagesDataUris, initialImagesDataUris) ||
            starredImageIndex !== initialState.current.starredIndex
        );
    };

    // For handling file upload
    const handleFileButtonClick = () => {
        if ([2, 4, 5].includes(inquiryStatus)) {
            toast.warn("در این مرحله نمی‌توانید پیش‌فاکتور جدیدی اضافه کنید.")
        } else {
            const fileInput = document.getElementById('fileInput');
            if (fileInput) {
                fileInput.click();
            }
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp',
            'image/webp', 'image/svg+xml', 'application/pdf'];
        setShowFileErrorMessage(false);

        if (file && acceptedImageTypes.includes(file.type)) {
            setFileLoading(true);
            const reader = new FileReader();
            reader.onload = (event) => {
                const uri = event.target.result;
                if (file.type === 'application/pdf') {
                    handleSelectedImage(uri, "pdf");
                    setFileLoading(false);
                } else {
                    compressImage(uri, 0.7, (error, compressedUri) => {
                        if (error) {
                            console.error("Compression error:", error);
                            handleSelectedImage(uri, "image");
                        } else {
                            handleSelectedImage(compressedUri, "image");
                        }
                        setFileLoading(false);
                    });
                }
            };
            reader.readAsDataURL(file);
        } else {
            setShowFileErrorMessage(true);
        }
    };

    const generateTableHeaders = () => {
        return [
            [
                {value: "ردیف", color: "black", width: "10%"},
                {value: "انتخاب من", color: "black", width: "20%"},
                {value: "نوع فایل", color: "black", width: "20%"},
                {value: ""}
            ]
        ];
    };

    const dataURItoBlob = (dataUri, fileType) => {
        const byteString = atob(dataUri.split(',')[1]);
        const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], {type: mimeString || fileType});
    };

    const handleDownload = (dataUri, fileType) => {
        const blob = dataURItoBlob(dataUri, fileType);
        const url = URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.download = `download.${fileType === 'pdf' ? 'pdf' : 'png'}`; // Default to png for images

        // Trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const handleOpenInquiryHistoryModal = () => {
        setShowInquiryHistoryModal(true);
    };

    const handleCloseInquiryHistoryModal = () => {
        setShowInquiryHistoryModal(false);
    };

    const generateTableRows = () => {
        if (images.length !== 0) {
            return images.map((image, index) => [
                {
                    value: index + 1,
                    color: "var(--darkGray-color)",
                    width: "10%"
                },
                {
                    value: (
                        <input
                            className={styles.customRadio}
                            type="radio"
                            checked={index === starredImageIndex}
                            onChange={() => handleStarImage(index)}
                            disabled={[2, 4, 5].includes(inquiryStatus)}
                        />
                    ),
                    width: "20%"
                },
                {
                    value: image.file_type === 'pdf' ? "پی‌دی‌اف" : "عکس", width: "20%"
                },
                {
                    value: (
                        <div className={styles.Icons}>
                            {/*<MdRemoveRedEye*/}
                            {/*    className={styles.EyeIcon}*/}
                            {/*    onClick={() => handleDownloadInNewTab(image.data_uri, image.file_type, index)}*/}
                            {/*    style={{cursor: 'pointer'}}*/}
                            {/*/>*/}
                            <IoMdDownload
                                className={styles.DownloadIcon}
                                onClick={() => handleDownload(image.data_uri, image.file_type, index)}
                                style={{cursor: 'pointer'}}
                            />
                            <FaRegTrashAlt
                                className={styles.TrashIcon}
                                onClick={() => handleDeleteImage(index)}
                                style={{cursor: 'pointer'}}
                                disabled={[2, 4, 5].includes(inquiryStatus)}
                            />
                        </div>
                    )
                },

            ]);
        } else {
            return [];
        }
    };


    if (loading) {
        return (
            <LoadingComponent isMobile={isMobile}/>
        );
    } else {
        return (
            <div
                className={styles.newInquiryContainer}>
                <ToastContainer className={toast_style}/>
                {isEdit ?
                    isMobile ?
                        <div className={styles.headingContent}>
                            <FaRegComment className={styles.CommentIcon}
                                          onClick={() => handleOpenInquiryHistoryModal()}/>

                            <div className={styles.NameContainer}>
                                <span className={styles.inquiryHeading}>
                                    ویرایش {inquiryName}
                                </span>
                                <FaChevronRight onClick={() => navigate("/user")} className={styles.goBackIcon}/>
                            </div>
                        </div>
                        :
                        <div className={styles.headingContent}>
                            <div className={styles.NameContainer}>
                                <span className={styles.inquiryHeading}>
                                    ویرایش {inquiryName}
                                </span>
                                <InquiryStatusIcon inquiryStatus={isInquiryReturned ? 6 : inquiryStatus}
                                                   isMobile={isMobile}/>
                            </div>
                        </div>
                    :
                    <div className={styles.headingContent}>
                        <span className={styles.inquiriesHeading}>ثبت درخواست جدید</span>
                    </div>
                }

                {isMobile && isEdit &&
                    <div className={styles.InfoContainer}>
                        <div className={styles.InfoField}>
                            <span className={styles.FieldText}>عنوان درخواست: </span>
                            <span className={styles.categoryAndDate}>
                                {inquirySubject}
                            </span>
                        </div>
                        <div className={styles.InfoField}>
                            <span className={styles.FieldText}>وضعیت درخواست: </span>
                            <InquiryStatusIcon inquiryStatus={isInquiryReturned ? 6 : inquiryStatus} isMobile={true}/>
                        </div>
                        <div className={styles.InfoField}>
                            <span className={styles.FieldText}>تاریخ ثبت در سامانه: </span>
                            <span className={styles.categoryAndDate}>
                                {convertDateToShamsi(inquirySubmittedDate)}
                            </span>
                        </div>
                    </div>
                }
                <div className={styles.newInquiryPageContainer}>
                    <div className={styles.newInquiry}>
                        {inquiryReturnedComment &&
                            <div className={styles.ReturnedCommentContainer}>
                                <span className={styles.ReturnedHeading}>
                                    این درخواست به دلیل زیر به شما ارجاع داده شده است و نیازمند اقدامی از سمت شما
                                    می‌باشد:
                                </span>
                                <span className={styles.returnedComment}>
                                    {inquiryReturnedComment}
                                </span>
                            </div>
                        }
                        {!isEdit && (
                            <div className={styles.SubjectTextbox} ref={showSubjectError ? firstErrorRef : null}>
                                <span className={styles.Field}>
                                    عنوان درخواست
                                    <span style={{color: 'red'}}> *</span>
                                    <FaInfoCircle
                                        style={{color: 'black', marginRight: '5px', cursor: 'pointer'}}
                                        onClick={handleOpenSubjectExplanation}
                                    />
                                </span>
                                <input
                                    value={inquirySubject}
                                    onChange={(e) => handleInquirySubjectChange(e)}
                                    placeholder={''}
                                    className={`${styles.subjectInputText} ${showSubjectError ? styles.inputTextError : ''}`}
                                    disabled={[2, 4, 5].includes(inquiryStatus)}
                                />
                                {showSubjectError && (
                                    <InlineError
                                        errorMessage={"عنوان مربوط به درخواست را بنویسید."}
                                        device={isMobile ? "mobile" : "desktop"}
                                    />
                                )}
                            </div>
                        )}
                        <div className={isEdit ? styles.newInquiryFileUpload : styles.newInquiryFileUploadNew}
                             ref={showImagesError ? firstErrorRef : null}>
                            <span className={styles.Field}>
                                بارگذاری پیش‌فاکتور‌ها
                                <span style={{color: 'red'}}> *</span>
                                <FaInfoCircle
                                    style={{color: 'black', marginRight: '5px', cursor: 'pointer'}}
                                    onClick={handleOpenUploadingExplanation}
                                />
                            </span>

                            {images.length > 0 &&
                                <div className={styles.imageTable}>
                                    <CustomTable
                                        headerData={generateTableHeaders()}
                                        data={generateTableRows()}
                                        styles={isMobile ? "mobile" : "desktop"}
                                    />
                                </div>
                            }
                            {showStarredImageError && (
                                <InlineError errorMessage={"پیش‌فاکتور مورد تایید خود را انتخاب کنید."}
                                             device={isMobile ? "mobile" : "desktop"}/>
                            )}
                            {isMobile ?
                                <div
                                    className={`${styles.uploadBox} 
                            ${[2, 4, 5].includes(inquiryStatus) ? styles.uploadBoxNoEdit : ''}
                            ${showImagesError ? styles.uploadBoxError : ''}
                            ${images.length > 0 ? styles.miniUploadBox : ''}`}
                                    onClick={handleFileButtonClick}>
                                    {images.length === 0 && <FaCloudUploadAlt className={styles.cloudIcon}/>}
                                    <span className={styles.uploadText}>اضافه کردن فایل جدید</span>
                                </div>
                                :
                                <button className={styles.AddButton} onClick={handleFileButtonClick}
                                        disabled={[2, 4, 5].includes(inquiryStatus)}>
                                    اضافه کردن فایل جدید
                                </button>
                            }

                            <input type="file" id="fileInput" className={styles.uploadInput}
                                   onChange={handleFileChange}/>
                        </div>

                        {showImagesError && (
                            <InlineError errorMessage={"حداقل یک پیش‌فاکتور در این قسمت آپلود کنید."}
                                         device={isMobile ? "mobile" : "desktop"}/>
                        )}
                        {showFileErrorMessage &&
                            <InlineError errorMessage={"فرمت فایل بارگذاری شده پشتیبانی نمی‌شود."}
                                         device={isMobile ? "mobile" : "desktop"}/>
                        }

                        <Dialog open={openSubjectExplanation} onClose={handleCloseSubjectExplanation}
                                sx={{fontFamily: 'IRANSansFaNum, serif', textAlign: 'right'}}>
                            <DialogTitle
                                sx={{fontFamily: 'IRANSansFaNum, serif', textAlign: 'center', fontWeight: 'bold'}}>توضیحات
                                برای عنوان درخواست</DialogTitle>
                            <DialogContent>
                                <ul style={{direction: "rtl", paddingRight: '10px', textAlign: 'justify'}}>
                                    <li>عنوان درخواست باید به وضوح محصولاتی را که قصد خرید آن‌ها را دارید مشخص کند.</li>
                                    <li>این عنوان باید دقیق و مختصر باشد تا به ناظر کمک کند سریعاً متوجه موضوع درخواست
                                        شما شود.
                                    </li>
                                    <li>از استفاده از کلماتی
                                        مانند <strong>"درخواست"</strong> یا <strong>"خرید"</strong> در عنوان خودداری
                                        کنید، زیرا این کلمات برای همه درخواست‌ها تکراری هستند و اطلاعات جدیدی ارائه
                                        نمی‌دهند.
                                    </li>
                                    <li>به جای آن، مستقیماً به نوع یا دسته‌بندی محصولاتی که نیاز دارید اشاره کنید.</li>
                                    <li>برای مثال، به جای عنوان <strong>"درخواست خرید لباس کار"</strong>، از
                                        عنوان <strong>"لباس کار"</strong> استفاده کنید. این نوع عنوان به
                                        طور واضح مشخص می‌کند که محصول مورد نظر شما چیست و نیاز به توضیحات اضافی ندارد.
                                    </li>
                                    <li>عناوین واضح و مختصر باعث تسریع در فرآیند بررسی و تایید درخواست شما می‌شوند.</li>
                                </ul>
                            </DialogContent>
                            <DialogActions sx={{justifyContent: 'center'}}>
                                <Button
                                    onClick={handleCloseSubjectExplanation}
                                    color="primary"
                                    sx={{fontFamily: 'IRANSansFaNum, serif', fontWeight: 'bold'}}
                                >
                                    بستن
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={openUploadingExplanation} onClose={handleCloseUploadingExplanation}
                                sx={{fontFamily: 'IRANSansFaNum, serif', textAlign: 'right'}}>
                            <DialogTitle
                                sx={{fontFamily: 'IRANSansFaNum, serif', textAlign: 'center', fontWeight: 'bold'}}>توضیحات
                                برای بارگذاری پیش‌فاکتورها</DialogTitle>
                            <DialogContent>
                                <ul style={{direction: "rtl", paddingRight: '10px', textAlign: 'justify'}}>
                                    <li>برای هر درخواست، <strong>حداقل یک پیش‌فاکتور</strong> آپلود کنید.</li>
                                    <li>بعد از آپلود پیش‌فاکتورها، یکی از آن‌ها را که پیشنهاد خودتان است، به
                                        عنوان <strong>«انتخاب من»</strong> مشخص کنید.
                                    </li>
                                    <li>پیش‌فاکتورهای آپلود شده باید در قالب <strong>فایل‌های تصویری</strong> (مانند jpg
                                        و png) و یا به صورت <strong>فایل PDF</strong> باشند.
                                    </li>
                                    <li>هنگام آپلود پیش‌فاکتور به صورت فایل تصویری، دقت کنید که عکس واضح بوده و
                                        نوشته‌های آن خوانا باشند.
                                    </li>
                                </ul>
                            </DialogContent>
                            <DialogActions sx={{justifyContent: 'center'}}>
                                <Button onClick={handleCloseUploadingExplanation} color="primary"
                                        sx={{fontFamily: 'IRANSansFaNum, serif', fontWeight: 'bold'}}
                                >
                                    بستن
                                </Button>
                            </DialogActions>
                        </Dialog>

                        {!isEdit &&
                            <div className={styles.CommentTextbox} ref={showCommentError ? firstErrorRef : null}>
                                <span className={styles.Field}>
                                    توضیحات درخواست
                                    <span style={{color: 'red'}}> *</span>
                                </span>
                                <AutoGrowInput
                                    value={inquiryDescription}
                                    onInputChange={handleInquiryDescriptionChange}
                                    placeholder={'به طور مثال شرایط پرداخت یا دلیل انتخاب یا ...'}
                                    styleClassName={`${styles.inputText} ${showCommentError ? styles.inputTextError : ''}`}
                                    disabled={[2, 4, 5].includes(inquiryStatus)}
                                />
                                {showCommentError && (
                                    <InlineError
                                        errorMessage={"توضیحات مربوط به درخواست را بنویسید. (مواردی مانند شرایط پرداخت یا دلیل انتخاب یا ...)"}
                                        device={isMobile ? "mobile" : "desktop"}/>
                                )}
                            </div>
                        }
                        <div className={styles.sendOrDiscardButtons}>
                            <button className={styles.sendButton}
                                    onClick={handleSendRequest}
                                    disabled={isSubmitting || !isFormModified()}
                            >
                                {isEdit ? "ویرایش درخواست" : "ساخت درخواست"}
                            </button>
                            <button className={styles.discardButton} onClick={handleDiscardButton}>
                                {inquiryStatus === 1 && canCancel ? "لغو درخواست" : "انصراف"}
                            </button>
                        </div>
                    </div>
                    {isEdit && !isMobile &&
                        <div className={styles.ThirdColumn}>
                            <div className={styles.InquiryHistoryTimeLine}>
                                <span className={styles.AuditorInquiryPageHeader}>گردش کار</span>
                                {isFetchingComments ?
                                    <div className={styles.loading_container}>
                                        <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)"
                                                      width='50px'
                                                      height='50px'/>
                                    </div>
                                    :
                                    <TimeLine items={timelineItems}/>
                                }
                            </div>
                            <div className={styles.AddingCommentContainer}>
                                <span className={styles.AuditorInquiryPageHeader}>اضافه کردن کامنت</span>
                                <AutoGrowInput
                                    placeholder={""}
                                    value={newComment}
                                    onInputChange={handleCommentChange}
                                    styleClassName={styles.commentInputField}
                                />
                                <button
                                    className={styles.SendButton}
                                    onClick={handleAddNewComment}
                                    disabled={isSendingComment}
                                >
                                    {isSendingComment ? 'لطفا صبر کنید ...' : 'ارسال'}
                                </button>
                            </div>
                        </div>
                    }
                </div>

                {
                    selectedImage && (
                        <div className={styles.enlargedImageContainer} onClick={() => setSelectedImage('')}>
                            <img src={selectedImage} alt="Enlarged Image" className={styles.enlargedImage}/>
                        </div>
                    )
                }
                {
                    uploadLoading && (
                        <div>
                            <LoadingComponent
                                isMobile={isMobile}
                                text={`در حال ساخت و بارگذاری فایل‌های ارسال شده، لطفا از صفحه خارج نشوید.`}
                                progress={uploadProgress}
                            />
                        </div>
                    )
                }
                {
                    fileLoading && (
                        <LoadingComponent isMobile={isMobile}/>
                    )
                }
                {selectedPdf && <PDFPreview selectedPdf={selectedPdf} setSelectedPdf={setSelectedPdf}/>}
                {showDeleteConfirmation && (
                    <ConfirmationDialog
                        message="آیا از حذف این فایل اطمینان دارید؟"
                        onConfirm={confirmDeleteImage}
                        onCancel={() => setShowDeleteConfirmation(false)}
                    />
                )}

                {showSendConfirmation && (
                    <ConfirmationDialog
                        message={isEdit ? "آیا از ویرایش این درخواست اطمینان دارید؟" : "آیا از ساخت این درخواست اطمینان دارید؟"}
                        onConfirm={confirmSendRequest}
                        onCancel={() => setShowSendConfirmation(false)}
                    />
                )}

                {showCancelConfirmation && (
                    <ConfirmationDialog
                        message="آیا از لغو این درخواست اطمینان دارید؟"
                        onConfirm={confirmCancelInquiry}
                        onCancel={() => setShowCancelConfirmation(false)}
                    />
                )}
                {
                    showInquiryHistoryModal && (
                        <InquiryHistory onClose={handleCloseInquiryHistoryModal} isMobile={true}/>
                    )
                }
                {showInquiryCreationSuccess &&
                    <SuccessDialog
                        message={`${inquiryName} با موفقیت ساخته شد.`}
                        details="برای پیگیری وضعیت درخواست خود می‌توانید به بخش 'درخواست‌ها' مراجعه کنید."
                        onConfirm={() => navigate('/user')}
                        buttonText="مشاهده درخواست‌ها"
                    />
                }
                {showInquiryEditionSuccess &&
                    <SuccessDialog
                        message={`${inquiryName} با موفقیت ویرایش شد.`}
                        details="برای پیگیری وضعیت درخواست خود می‌توانید به بخش 'درخواست‌ها' مراجعه کنید."
                        onConfirm={() => navigate('/user')}
                        buttonText="مشاهده درخواست‌ها"
                    />
                }
                {showInquiryCancellationSuccess &&
                    <SuccessDialog
                        message={`${inquiryName} با موفقیت لغو شد.`}
                        details="شما می‌توانید جزئیات این درخواست را در بخش 'درخواست‌ها'، قسمت 'لغو شده'، مشاهده کنید. توجه داشته باشید که این درخواست به کارشناس نمایش داده نخواهد شد."
                        onConfirm={() => navigate('/user')}
                        buttonText="مشاهده درخواست‌ها"
                    />
                }
            </div>
        );
    }
};

NewInquiryModule.propTypes = {
    isEdit: PropTypes.bool.isRequired,
};

export default NewInquiryModule;