import styles from "../../styles/lists/EntitySection.module.css"
import {IoGridOutline} from "@react-icons/all-files/io5/IoGridOutline";
import React, {useEffect, useState} from "react";
import Pagination from "../../components/Pagination/Pagination";
import CustomTable from "../../components/CustomTable/CustomTable";
import LoadingComponent from "../../components/Loading/Loading";
import EditEntityModal from "./EditEntityModal";
import ConfirmationDialog from "../../components/Confirmation/ConfirmationDialog";

const EntityListSection = ({
                               entityType,
                               sortDict,
                               fetchEntities,
                               editEntity,
                               headerText,
                               newButtonText,
                               generateTableConfig,
                               editModalTitle,
                               editModalFields,
                               newModalTitle,
                               newModalFields,
                               newEntity,
                               handleDisable
                           }) => {
    const [loading, setLoading] = useState(false);
    const [entities, setEntities] = useState([]);
    const [sortConfig, setSortConfig] = useState({key: null, direction: null});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalEntities, setTotalEntities] = useState(1);
    const [entitiesPerPage] = useState(8);
    const [searchQuery, setSearchQuery] = useState("");
    const [performSearch, setPerformSearch] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isNewModalOpen, setIsNewModalOpen] = useState(false);
    const [showDisableConfirmation, setShowDisableConfirmation] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState(null);

    const handleSearchQueryChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePerformSearch = () => {
        setPerformSearch(prevState => !prevState);
    };

    const handleEditClick = (entity) => {
        setSelectedEntity(entity);
        setIsEditModalOpen(true);
    };

    const handleSaveEntity = async (updatedEntity) => {
        if (editEntity) {
            await editEntity(updatedEntity);
        }
        await fetchAllPaginatedEntities();
    };

    const handleNewClick = () => {
        if (isEditModalOpen === false) {
            setIsNewModalOpen(true);
        }
    };

    const handleSaveNewEntity = async (entity) => {
        if (entity) {
            if (entityType === "stores") {
                await newEntity(entity.name, entity.phone_number);
            } else if (entityType === "products") {
                await newEntity(entity);
            } else if (entityType === "orgs") {
                await newEntity(entity.name, entity.code);
            }
        }
        await fetchAllPaginatedEntities();
    };

    const handleDisableClick = (entity) => {
        setSelectedEntity(entity);
        setShowDisableConfirmation(true);
    };

    const fetchAllPaginatedEntities = async () => {
        try {
            setLoading(true);
            const response = await fetchEntities(
                currentPage,
                entitiesPerPage,
                sortDict[sortConfig.key],
                sortConfig.direction,
                searchQuery
            );
            setCurrentPage(response.data.current_page);
            setTotalPages(response.data.total_pages);
            setEntities(response.data[entityType]);
            setTotalEntities(response.data.total_items);
        } catch (error) {
            console.error(`Error in fetching paginated ${entityType}: `, error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAllPaginatedEntities();
    }, [sortConfig, currentPage, performSearch]);

    const handleSort = (columnIndex) => {
        const newDirection = sortConfig.key === columnIndex && sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({key: columnIndex, direction: newDirection});
    };

    const {headers, transformRow} = generateTableConfig(handleEditClick, handleDisableClick);

    function generateTableRows() {
        if (entities.length !== 0) {
            return entities.map(transformRow);
        }
        return [];
    }

    const tableRows = generateTableRows();
    const tableHeaders = [headers];

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

    return (
        <div className={styles.entity_container}>
            <div className={styles.section_header_container}>
                <IoGridOutline className={styles.section_icon}/>
                <span className={styles.section_header}>
                    {headerText}
                </span>
            </div>
            <div className={styles.search_or_new_container}>
                <div className={styles.search_container}>
                    <input
                        className={styles.search_input}
                        placeholder={"عبارت مورد جستجو"}
                        value={searchQuery}
                        onChange={handleSearchQueryChange}
                    />
                    <button className={styles.search_button} onClick={handlePerformSearch}>
                        جستجو
                    </button>
                </div>
                {newButtonText &&
                    <button className={styles.new_button} onClick={handleNewClick}>
                        {newButtonText}
                    </button>
                }
            </div>

            <div className={styles.table_container}>
                {loading ? (
                    <LoadingComponent isMobile={false}/>
                ) : (
                    <div className={styles.EntityTableContainer}>
                        <CustomTable
                            headerData={tableHeaders}
                            data={tableRows}
                            styles={"desktop"}
                            sortConfig={sortConfig}
                            onSort={handleSort}
                        />
                    </div>
                )}

                {!loading && entities.length > 0 && (
                    <div className={styles.PaginationWrapper}>
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={setCurrentPage}
                        />
                        <div className={styles.ItemCount}>
                            نمایش
                            موارد {indexOfFirstEntity + 1} تا {Math.min(indexOfLastEntity, totalEntities)} از {totalEntities} مورد
                        </div>
                    </div>
                )}
            </div>
            {isEditModalOpen && (
                <EditEntityModal
                    entityType={entityType}
                    open={isEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    entity={selectedEntity}
                    onSave={handleSaveEntity}
                    title={editModalTitle}
                    fields={editModalFields}
                />
            )}
            {isNewModalOpen && (
                <EditEntityModal
                    entityType={entityType}
                    open={isNewModalOpen}
                    onClose={() => setIsNewModalOpen(false)}
                    entity={null}
                    onSave={handleSaveNewEntity}
                    title={newModalTitle}
                    fields={newModalFields}
                />
            )}
            {showDisableConfirmation && (
                <ConfirmationDialog
                    message={`آیا از غیرفعال کردن این ${entityType === "orgs" ? "سازمان" : "کاربر"} اطمینان دارید؟ (توجه داشته باشید که با غیرفعال کردن هیچ داده‌ای از دست نخواهد رفت.)`}
                    onConfirm={async () => {
                        await handleDisable(selectedEntity);
                        setShowDisableConfirmation(false);
                        await fetchAllPaginatedEntities();
                    }}
                    onCancel={() => setShowDisableConfirmation(false)}
                />
            )}
        </div>
    );
};

export default EntityListSection;