import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { uniqueKey } from "./util";
import "./CustomTableRow.css";
import { IoIosArrowDown } from "@react-icons/all-files/io/IoIosArrowDown";
import { IoIosArrowUp } from "@react-icons/all-files/io/IoIosArrowUp";

function useInputState(initialValue) {
    const [value, setValue] = useState(initialValue);
    const onChange = useCallback((newValue) => {
        setValue(newValue);
    }, []);
    return [value, onChange];
}

const arabicToEnglishDigits = (str) => {
    try {
        str = String(str); // Convert to string if it's not already
        return str.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
            .replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));
    } catch (error) {
        console.error("Error converting Arabic digits to English:", error);
        return str; // Return the original input if an error occurs
    }
};

function EditableCell({ initialValue, onChange, checkHasError }) {
    const [value, setValue] = useState(initialValue);

    const formatValue = (val) => {
        if (val === "" || isNaN(val)) return val;
        return parseFloat(val).toLocaleString();
    };

    const handleChange = (e) => {
        const inputValue = arabicToEnglishDigits(e.target.value).replace(/,/g, '');
        setValue(formatValue(inputValue));
    };

    const handleBlur = () => {
        const formattedValue = value.replace(/,/g, '');
        onChange(Number(formattedValue));
    };

    let hasError = false;

    if (checkHasError) {
        if (value === "0" || value === "") {
            hasError = true;
        }
    }

    return (
        <input
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`inline-input ${hasError ? 'error-field' : ''}`}
        />
    );
}

const CustomTableRow = ({ rowData, borderBottom, bold, rowIndex, styles, onHeaderClick, isHeader, sortConfig }) => {
    const isOddRow = rowIndex % 2 === 1; // Check if rowIndex is odd

    return (
        <div
            className={`row_${styles} ${borderBottom ? "border-bottom" : ""} ${isHeader ? "dark-gray-background" : isOddRow ? "gray-background" : "white-background"
                }`}
        >
            {rowData.map((col, index) => (
                <div
                    className={`row_${styles}`}
                    key={uniqueKey(`${col.value}-${index}`)}
                    style={{
                        flex: col.width ? "none" : "1",
                        width: col.width || "auto",
                        fontWeight: bold || col.bold ? "bold" : "normal",
                        color: `${col.color}`,
                        fontSize: `${col.fontSize}`,
                        paddingTop: col.paddingBottom ? col.paddingTop : "inherit",
                        paddingBottom: col.paddingTop ? col.paddingBottom : "inherit",
                    }}
                >
                    {col.isInput ? (
                        <EditableCell
                            initialValue={col.value === 0 ? "" : col.value}
                            onChange={col.onChange}
                            checkHasError={col.checkHasError}
                        />
                    ) : (
                        <span>{col.value}</span>
                    )}
                    {col.hasOwnProperty('sort') && col.sort && (
                        <div className="sorting-arrows">
                            <IoIosArrowUp
                                className={`arrow-up ${sortConfig.key === index && sortConfig.direction === 'asc' ? 'active' : ''}`}
                                onClick={() => onHeaderClick(index)}
                            />
                            <IoIosArrowDown
                                className={`arrow-down ${sortConfig.key === index && sortConfig.direction === 'desc' ? 'active' : ''}`}
                                onClick={() => onHeaderClick(index)}
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

CustomTableRow.propTypes = {
    rowData: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.node
            ]),
            width: PropTypes.string
        })
    ),
    borderBottom: PropTypes.bool,
    bold: PropTypes.bool,
    rowIndex: PropTypes.number,
    styles: PropTypes.oneOf(['desktop', 'mobile']).isRequired,
    onHeaderClick: PropTypes.func,
    isHeader: PropTypes.bool
};

export default CustomTableRow;
