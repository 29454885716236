import {MdModeEdit} from "@react-icons/all-files/md/MdModeEdit";
import EntityListSection from "./EntityListSection";
import {addProduct, editProductById, getAllPaginatedProducts} from "../../APIs/product";
import {IoIosMore} from "@react-icons/all-files/io/IoIosMore";
import ProductDetailsModal from "../../components/ProductDetailsModal/ProductDetailsModal";
import {useState} from "react";

const ProductsSection = () => {
    const [selectedProduct, setSelectedProduct] = useState(null); // State to hold selected product for details modal
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false); // State to control details modal visibility
    const handleMoreDetailsClick = (product) => {
        setSelectedProduct(product);
        setIsDetailsModalOpen(true);
    };

    const generateTableConfig = (handleEditClick) => ({
        headers: [
            {value: "گروه کالا", sort: true},
            {value: "زیرگروه کالا", sort: true},
            {value: "برند کالا", sort: true},
            {value: "نام کالا", sort: true},
            {value: "جزئیات بیشتر", width: "150px"},
            {value: "ویرایش", width: "50px"}
        ],
        transformRow: (product) => [
            {value: product.main_category},
            {value: product.sub_category},
            {value: product.brand},
            {value: product.name},
            {
                value: (
                    <IoIosMore
                        style={{cursor: "pointer", fontWeight: "bold"}}
                        onClick={() => handleMoreDetailsClick(product)}
                    />
                ),
                width: "150px"
            },
            {
                value: (
                    <MdModeEdit
                        style={{cursor: "pointer"}}
                        onClick={() => handleEditClick(product)}
                    />
                ),
                width: "50px"
            }
        ]
    });

    return (
        <>
            <EntityListSection
                entityType="products"
                sortDict={{0: "main_category", 1: "sub_category", 2: "brand", 3: "name"}}
                fetchEntities={getAllPaginatedProducts}
                editEntity={editProductById}
                headerText="فهرست کالاهای ثبت شده"
                newButtonText="کالای جدید"
                generateTableConfig={generateTableConfig}
                editModalTitle={"ویرایش مشخصات کالا"}
                editModalFields={[
                    {name: 'name', label: 'نام کالا', type: 'dropdown'},
                    {name: 'main_category', label: 'گروه کالا', type: 'dropdown'},
                    {name: 'sub_category', label: 'زیرگروه کالا', type: 'dropdown'},
                    {name: 'brand', label: 'برند کالا', type: 'dropdown'},
                    {name: 'attributes', label: 'ویژگی‌های کالا', type: 'dropdown'},
                ]}
                newModalTitle={"اضافه کردن کالای جدید"}
                newModalFields={[
                    {name: 'name', label: 'نام کالا', type: 'dropdown'},
                    {name: 'main_category', label: 'گروه کالا', type: 'dropdown'},
                    {name: 'sub_category', label: 'زیرگروه کالا', type: 'dropdown'},
                    {name: 'brand', label: 'برند کالا', type: 'dropdown'},
                    {name: 'attributes', label: 'ویژگی‌های کالا', type: 'dropdown'},
                ]}
                newEntity={addProduct}
                handleDisable={null}
            />
            {isDetailsModalOpen && selectedProduct && (
                <ProductDetailsModal
                    product={selectedProduct}
                    onClose={() => setIsDetailsModalOpen(false)}
                    open={isDetailsModalOpen}
                />
            )}
        </>

    );
};

export default ProductsSection;