import styles from "../../styles/dashboard/AuditorDashboardDesktop.module.css";
import {AUDITOR_LISTS_OPTION_LABELS, AUDITOR_LISTS_OPTIONS} from "./consts";
import {useState} from "react";
import SellersSection from "./SellersSection";
import OrganizationsSection from "./OrganizationsSection";
import ProductsSection from "./ProductsSection";
import UsersSection from "./UsersSection";

const Lists = () => {
    const [option, setOption] = useState(AUDITOR_LISTS_OPTIONS.ORGANIZATIONS);

    return (
        <div className={styles.main_container}>

            <div className={styles.top_bar}>
                <span className={styles.header_title}>پنل مدیریتی</span>
                <div className={styles.vertical_divider}></div>
                <button
                    className={styles.header_button}
                    disabled={option === AUDITOR_LISTS_OPTIONS.ORGANIZATIONS}
                    onClick={() => setOption(AUDITOR_LISTS_OPTIONS.ORGANIZATIONS)}>
                    {AUDITOR_LISTS_OPTION_LABELS[AUDITOR_LISTS_OPTIONS.ORGANIZATIONS]}
                </button>
                <button
                    className={styles.header_button}
                    disabled={option === AUDITOR_LISTS_OPTIONS.USERS}
                    onClick={() => setOption(AUDITOR_LISTS_OPTIONS.USERS)}>
                    {AUDITOR_LISTS_OPTION_LABELS[AUDITOR_LISTS_OPTIONS.USERS]}
                </button>
                <button
                    className={styles.header_button}
                    disabled={option === AUDITOR_LISTS_OPTIONS.PRODUCTS}
                    onClick={() => setOption(AUDITOR_LISTS_OPTIONS.PRODUCTS)}>
                    {AUDITOR_LISTS_OPTION_LABELS[AUDITOR_LISTS_OPTIONS.PRODUCTS]}
                </button>
                <button
                    className={styles.header_button}
                    disabled={option === AUDITOR_LISTS_OPTIONS.SELLERS}
                    onClick={() => setOption(AUDITOR_LISTS_OPTIONS.SELLERS)}>
                    {AUDITOR_LISTS_OPTION_LABELS[AUDITOR_LISTS_OPTIONS.SELLERS]}
                </button>

            </div>

            <div className={styles.lists_container}>

                {option === AUDITOR_LISTS_OPTIONS.SELLERS &&
                    <SellersSection/>
                }

                {option === AUDITOR_LISTS_OPTIONS.ORGANIZATIONS &&
                    <OrganizationsSection/>
                }

                {option === AUDITOR_LISTS_OPTIONS.PRODUCTS &&
                    <ProductsSection/>
                }

                {option === AUDITOR_LISTS_OPTIONS.USERS &&
                    <UsersSection/>
                }

            </div>

        </div>
    );
}

export default Lists;