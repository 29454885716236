export const AUDITOR_LISTS_OPTIONS = {
    SELLERS: 1,
    ORGANIZATIONS: 2,
    PRODUCTS: 3,
    USERS: 4,
};

export const AUDITOR_LISTS_OPTION_LABELS = {
    [AUDITOR_LISTS_OPTIONS.SELLERS]: 'فروشندگان',
    [AUDITOR_LISTS_OPTIONS.ORGANIZATIONS]: 'سازمان‌ها',
    [AUDITOR_LISTS_OPTIONS.PRODUCTS]: 'کالاها',
    [AUDITOR_LISTS_OPTIONS.USERS]: 'کاربران',
};


export const LISTS_ERROR_MESSAGES = {
    "STORE_NAME_EXISTS": "نام فروشگاهی که انتخاب کرده‌اید پیش‌تر ثبت شده است. لطفاً نام دیگری وارد کنید.",
    "ORG_NAME_EXISTS": "نام سازمانی که انتخاب کرده‌اید پیش‌تر ثبت شده است. لطفاً نام دیگری وارد کنید.",
    "PRODUCT_EXISTS": "مشخصات کالایی که انتخاب کرده‌اید کاملا مشابه کالایی است که پیش‌تر ثبت شده است.",
    "EMPTY_INPUT": "لطفاً فیلدهای لازم را پر کنید."
};
