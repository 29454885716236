import axios from "./base";

export function getAllPaginatedUsers(page, pageSize, sort_key, sort_direction, search_query) {
    const params = new URLSearchParams();

    if (sort_key) {
        params.append('sort_key', sort_key);
    }
    if (sort_direction) {
        params.append('sort_direction', sort_direction);
    }
    if (search_query) {
        params.append('search_query', search_query);
    }
    params.append('page', page);
    params.append('page_size', pageSize);

    return axios.get(`user/all/paginated/?${params.toString()}`);
}