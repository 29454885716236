import axios from './base';

export function signup(fullname, phone, email, organization_code, username, password) {
    return axios.post('user/add-user/', {
        fullname: fullname,
        email: email,
        phone: phone,
        username: username,
        password: password,
        organization_code: organization_code
    });
}

export function login(username, password) {
    return axios.post('token/', {username: username, password: password});
}

export function logout(refresh_token) {
    return axios.post('user/logout/', {refresh_token: refresh_token}, {withCredentials: true});
}

export function refreshToken(refresh_token) {
    return axios.post('token/refresh/', {refresh: refresh_token});
}

export function fetchCurrentUser() {
    return axios.get('user/current/');
}

export function deactivateUser(user) {
    return axios.post(`user/${user.id}/deactivate/`);
}
