import EntityListSection from "./EntityListSection";
import convertDateToShamsi from "../../utils/functions";
import {getAllPaginatedUsers} from "../../APIs/user";
import {MdDoNotDisturb} from "@react-icons/all-files/md/MdDoNotDisturb";
import {deactivateUser} from "../../APIs/auth";

const UsersSection = () => {
    const generateTableConfig = (handleEditClick, handleDisableClick) => ({
        headers: [
            {value: "نام کاربر", sort: true},
            {value: "سازمان", sort: true},
            {value: "تاریخ عضویت", sort: true},
            {value: "تعداد درخواست‌ها", sort: true},
            {value: "وضعیت فعالیت"},
            {value: "غیرفعال کردن", width: "100px"}
        ],
        transformRow: (user) => [
            {value: user.fullname},
            {value: user.organization},
            {value: convertDateToShamsi(user.date_joined)},
            {value: user.inquiry_count},
            {value: user.is_active ? "فعال" : "غیرفعال"},
            {
                value: (
                    <MdDoNotDisturb
                        style={{
                            cursor: user.is_active ? "pointer" : "not-allowed",
                            color: user.is_active ? "red" : "gray",
                            opacity: user.is_active ? 1 : 0.5,
                        }}
                        onClick={user.is_active ? () => handleDisableClick(user) : undefined}
                    />
                ),
                width: "100px"
            }
        ]
    });

    return (
        <EntityListSection
            entityType="users"
            sortDict={{0: "fullname", 1: "organization", 2: "date_joined", 3: "inquiry_count"}}
            fetchEntities={getAllPaginatedUsers}
            headerText="فهرست کاربران ثبت شده"
            generateTableConfig={generateTableConfig}
            handleDisable={deactivateUser}
        />
    );
};

export default UsersSection;