import React, {useState, useEffect, useRef} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
} from '@mui/material';
import {styled} from '@mui/material/styles';

import {LISTS_ERROR_MESSAGES} from "./consts"
import SelectProduct from "../auditor/desktop/SelectProduct";

const StyledDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialog-paper': {
        width: '500px',
        maxWidth: '90vw',
        padding: theme.spacing(2),
    },
    '& *': {
        fontFamily: 'IRANSans !important',
    },
    '& .MuiDialogTitle-root': {
        padding: theme.spacing(1, 2),
        textAlign: 'center',
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1, 2),
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        '& .MuiButton-root': {
            width: '20%',
        }
    },
    '& .MuiTextField-root': {
        marginBottom: theme.spacing(2),
    },
}));

const EditEntityModal = ({
                             entityType,
                             open,
                             onClose,
                             entity,
                             onSave,
                             title = "ویرایش مشخصات",
                             fields = [] // Array of field configurations
                         }) => {
    const [formData, setFormData] = useState({});
    const [error, setError] = useState(null);

    // For handling product
    const selectProductRef = useRef();
    const handleValidateProduct = () => {
        return selectProductRef.current.productValidataion();
    }

    // Initialize formData when entity or fields change
    useEffect(() => {
        if (entity) {
            const initialFormData = {};
            fields.forEach(field => {
                initialFormData[field.name] = entity[field.name] || '';
            });
            setFormData(initialFormData);
        }
    }, [entity, fields]);

    const [showGenerateButton, setShowGenerateButton] = useState(false);
    const generateRandomCode = (englishName) => {
        if (!englishName) return '';
        const randomNum = Math.floor(10000 + Math.random() * 90000);
        return `${englishName}-${randomNum}`;
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        if (name === 'english_name') {
            if (value !== '' && !/^[a-zA-Z\s]*$/.test(value)) {
                setError('نام سازمان به انگلیسی فقط می‌تواند شامل حروف انگلیسی باشد.');
                return;
            }
            setShowGenerateButton(true);
            setError(null);
        }

        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        try {
            // Add the entity's ID to the form data
            let submitData = {
                ...formData,
                id: entity ? entity.id : null
            };

            const codeValue = formData.code ? formData.code : entityType === "orgs" && entity ? entity.code : null;
            if (codeValue !== null) {
                submitData.code = codeValue;
            }

            if (entityType === "products") {
                let isProductValid = await handleValidateProduct();
                if (!isProductValid) {
                    return;
                }

                const productDetails = selectProductRef.current.getProductDetails();
                submitData = {
                    ...formData,
                    id: entity ? entity.id : null,
                    main_category: productDetails.main_category,
                    sub_category: productDetails.sub_category,
                    brand: productDetails.brand,
                    name: productDetails.name,
                    attributes: productDetails.attributes
                };

            }

            await onSave(submitData);
            onClose();
        } catch (error) {
            // Handle specific error cases
            if (error.response && error.response.status === 400) {
                setError(LISTS_ERROR_MESSAGES[error.response.data.error_code]);
            } else if (error.response && error.response.status === 500) {
                setError('خطایی در اتصال به سرور رخ داده است. لطفا بعداً امتحان کنید.');
            } else {
                setError('خطایی رخ داده است. لطفا از پر بودن فیلدهای لازم اطمینان حاصل کنید.');
            }
        }
    };

    return (
        <StyledDialog
            open={open}
            onClose={onClose}
            aria-labelledby="edit-entity-dialog-title"
            dir="rtl"
        >
            <DialogTitle id="edit-entity-dialog-title" color="grey" fontWeight="bold">
                {title}
            </DialogTitle>
            {entityType === "products" ?
                <div style={{padding: '2vw'}}>
                    <SelectProduct
                        ref={selectProductRef}
                        initiallySelectedProduct={
                            entity ? {
                                value: entity.id,
                                label: entity.name
                            } : null
                        }
                    />
                    {error && (
                        <div style={{color: 'red', marginTop: '16px'}}>{error}</div>
                    )}
                </div>
                :
                <DialogContent>
                    {fields.map((field, index) => (
                        <React.Fragment key={field.name}>
                            <TextField
                                margin="dense"
                                name={field.name}
                                label={field.label}
                                type={field.type || 'text'}
                                fullWidth
                                variant="outlined"
                                value={formData[field.name] || ''}
                                onChange={handleChange}
                                disabled={field.name === 'code'}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        // Find the next input field
                                        const nextField = fields[index + 1];
                                        if (nextField) {
                                            // Focus the next field if it exists
                                            const nextInput = document.querySelector(`input[name="${nextField.name}"]`);
                                            if (nextInput) {
                                                nextInput.focus();
                                            }
                                        }
                                    }
                                }}
                                InputProps={{
                                    style: {textAlign: 'right', direction: 'rtl'}
                                }}
                                InputLabelProps={{
                                    style: {left: 'auto', right: '1.75rem'}
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& > fieldset': {
                                            textAlign: 'right',
                                        },
                                    },
                                    '& .MuiInputLabel-root': {
                                        transformOrigin: 'right !important'
                                    },
                                }}
                            />
                            {field.name === 'code' && showGenerateButton && (
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={() => setFormData(prev => ({
                                        ...prev,
                                        code: generateRandomCode(formData.english_name)
                                    }))}
                                    sx={{mt: 1, fontWeight: "bold"}}
                                >
                                    تولید کد سازمان
                                </Button>
                            )}
                            {field.name === 'code' && formData.code && (
                                <Button
                                    variant="text"
                                    size="small"
                                    onClick={() => navigator.clipboard.writeText(formData.code)}
                                    sx={{mt: 1, mr: 1, fontWeight: "bold"}}
                                >
                                    کپی کد
                                </Button>
                            )}
                        </React.Fragment>
                    ))}
                    {error && (
                        <div style={{color: 'red', marginTop: '16px'}}>{error}</div>
                    )}
                </DialogContent>
            }
            <DialogActions>
                <Button
                    onClick={onClose}
                    variant="outlined"
                    sx={{
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        fontWeight: 'bold',
                        borderRadius: '10px',
                        '&:hover': {
                            color: '#FF0000',
                            borderColor: '#FF0000',
                            backgroundColor: 'rgba(255, 0, 0, 0.04)',
                        },
                        '&:focus': {
                            outline: 'none',
                        },
                        '&.MuiButtonBase-root:focus-visible': {
                            outline: 'none',
                            boxShadow: 'none',
                        }
                    }}
                >
                    انصراف
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{
                        color: 'white',
                        borderColor: '#1087FF',
                        backgroundColor: '#1087FF',
                        fontWeight: 'bold',
                        borderRadius: '10px'
                    }}
                >
                    ثبت
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};

export default EditEntityModal;