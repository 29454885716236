import axios from "./base";

export function getAllStores() {
    return axios.get(`store/all/`);
}

export function getAllPaginatedStores(page, pageSize, sort_key, sort_direction, search_query) {
    const params = new URLSearchParams();

    if (sort_key) {
        params.append('sort_key', sort_key);
    }
    if (sort_direction) {
        params.append('sort_direction', sort_direction);
    }
    if (search_query) {
        params.append('search_query', search_query);
    }
    params.append('page', page);
    params.append('page_size', pageSize);

    return axios.get(`store/all/paginated/?${params.toString()}`);
}

export function createStore(store_name, store_phone) {
    return axios.post(`store/add/`, {
        name: store_name,
        phone_number: store_phone
    });
}

export function getStoreById(store_id) {
    return axios.get(`store/${store_id}/`);
}

export function editStoreById(updated_store) {
    return axios.post(`store/${updated_store.id}/edit/`,
        {
            name: updated_store.name, phone: updated_store.phone_number
        }
    );
}