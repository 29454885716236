import axios from "./base";

export function getAllMainCategories() {
    return axios.get(`/product/main-categories/`);
}

export function getSubCategories(mainCategory = null) {
    const params = new URLSearchParams();
    if (mainCategory) {
        params.append('main_category', mainCategory);
    }

    return axios.get(`/product/sub-categories/?${params.toString()}`);
}

export function getBrands(mainCategory = null, subCategory = null) {
    const params = new URLSearchParams();
    if (mainCategory) {
        params.append('main_category', mainCategory);
    }
    if (subCategory) {
        params.append('sub_category', subCategory);
    }

    return axios.get(`/product/brands/?${params.toString()}`);
}

export function searchProducts(mainCategory = null, subCategory = null, brand = null, name = null) {
    const params = new URLSearchParams();

    if (mainCategory) {
        params.append('main_category', mainCategory);
    }
    if (subCategory) {
        params.append('sub_category', subCategory);
    }
    if (brand) {
        params.append('brand', brand);
    }
    if (name) {
        params.append('name', name);
    }
    return axios.get(`/product/get/?${params.toString()}`);
}

export function getProductAttributeKeys(mainCategory = null) {
    const params = new URLSearchParams();

    if (mainCategory) {
        params.append('main_category', mainCategory);
    }
    return axios.get(`/product/attributes-keys/?${params.toString()}`);
}

export function getProductAttributeValues(mainCategory = null, key = null) {
    const params = new URLSearchParams();

    if (mainCategory) {
        params.append('main_category', mainCategory);
    }
    if (key) {
        params.append('key', key);
    }
    return axios.get(`/product/attributes-values/?${params.toString()}`);
}

export function addProduct(newProductData) {
    return axios.post(`product/add/`, newProductData);
}

export function addReceiptItem(receipt_id, product_id, unit, unit_price, quantity) {
    return axios.post(`receipt/add-item/`, {
        receipt_id: receipt_id,
        product_id: product_id,
        unit: unit,
        unit_price: unit_price,
        quantity: quantity
    });
}

export function getProductById(productId) {
    return axios.get(`/product/${productId}/`);
}

export function getAllPaginatedProducts(page, pageSize, sort_key, sort_direction, search_query) {
    const params = new URLSearchParams();

    if (sort_key) {
        params.append('sort_key', sort_key);
    }
    if (sort_direction) {
        params.append('sort_direction', sort_direction);
    }
    if (search_query) {
        params.append('search_query', search_query);
    }
    params.append('page', page);
    params.append('page_size', pageSize);

    return axios.get(`product/all/paginated/?${params.toString()}`);
}

export function editProductById(updated_product) {
    return axios.post(`product/${updated_product.id}/edit/`,
        {
            name: updated_product.name,
            main_category: updated_product.main_category,
            sub_category: updated_product.sub_category,
            brand: updated_product.brand,
            attributes: updated_product.attributes
        }
    );
}