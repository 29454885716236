import axios from "./base";

export function getAllPaginatedOrganizations(page, pageSize, sort_key, sort_direction, search_query) {
    const params = new URLSearchParams();

    if (sort_key) {
        params.append('sort_key', sort_key);
    }
    if (sort_direction) {
        params.append('sort_direction', sort_direction);
    }
    if (search_query) {
        params.append('search_query', search_query);
    }
    params.append('page', page);
    params.append('page_size', pageSize);

    return axios.get(`user/organizations/paginated/?${params.toString()}`);
}

export function editOrgByCode(updated_org) {
    return axios.post(`user/organization/${updated_org.code}/edit/`,
        {
            name: updated_org.name
        }
    );
}

export function createOrganization(name, code) {
    return axios.post(`user/organization/add/`, {
        name: name,
        code: code
    });
}

export function deactivateOrganization(org) {
    return axios.post(`user/organization/${org.code}/deactivate/`);
}